(function ($, undefined) {
    /**
     * Namespace
     * @var object
     */
    var ns = namespace('app.common');

    /**
     * Initializes namespace
     */
    ns.init = function() {
        $(document).on('click',  '.no-autorization',   ns.authorization);
        $(document).on('click',  '.reg-in-log',        ns.getAuthorization);
        $(document).on('click',  '.results-trigger',   ns.setUserVote);
        $(document).on('click',  '.reload-graphic',    ns.clickReloadGraphic);

        // Start calendar
        $(document).on('click',  '.calnavview',        ns.clickCalendarView);
        $(document).on('click',  '.calnavyear',        ns.clickCalendarYear);
        $(document).on('click',  '.calnavmon',         ns.clickCalendarMonth);
        $(document).on('click',  '.calnavday',         ns.clickCalendarDay);
        $(document).on('click',  '.to-event',          ns.clickToEvent);
        $(document).on('mousedown',  '.click-calendar',    ns.clickSidebarCalendarDay); // Sidebar callendar get date
        // End calendar

        $(document).on('click', '.change-tab',         ns.clickChangeTab);

        $(document).on('submit', '#auth-form',         ns.submitAuthForm);
        $(document).on('submit', '#reg-form',          ns.submitRegForm);
        $(document).on('click',  '.mfp-close',         ns.closePopup);

        $(document).on('submit', '.comment-form',      ns.submitCommentForm);
        $(document).on('click',  '.check-child-form',  ns.checkChildCommentForm);

        $(document).on('submit', '#subscribe-form',    ns.submitSubscribeForm);

        ns.initGraphics();
        ns.clickLinkToAllGraphics();
        ns.initCalendar();
        ns.initCalendarDay();
    };

    ns.clickLinkToAllGraphics = function() {
      $('iframe.linkable').load(function(){
        $(this).contents().find("body").on('click', function(event) { window.location.href = $('#header-iframes').data('route') });
      });
    };

    ns.getAuthorization = function(e) {
      e.preventDefault();
      $("#create-account").removeClass('log-in-active');
      $("#log-in").addClass('log-in-active');
    };

    ns.closePopup = function(e) {
      e.preventDefault();

      var $btn   = $(e.currentTarget),
          id     = $btn.parents('.white-popup.mfp-with-anim.mfp-hide').attr('id');

      $("#" + id).removeClass('log-in-active');
    }

    // Start calendar
    ns.clickCalendarView = function(e) {
      e.preventDefault();

      var $btn   = $(e.currentTarget);

      $('.calnavview').removeClass('clocknav_current');
      $btn.addClass('clocknav_current');

      ns.getCalendarInfo();
    };

    ns.clickCalendarYear = function(e) {
      e.preventDefault();

      var $btn    = $(e.currentTarget),
          whatDo  = $btn.data('do-year'),
          newYear = parseInt($('.tabspace').data('year')) + parseInt(whatDo);

      $('.calnavyear').first().find('a').text(' << ' + (newYear - 1));
      $('.calnavyear').last().find('a').text((newYear + 1) + ' >> ');
      $('.tabspace').data('year', newYear);

      ns.getCalendarInfo();
    };

    ns.clickCalendarMonth = function(e) {
      e.preventDefault();

      var $btn   = $(e.currentTarget);

      $('.calnavmon').removeClass('monthnav_current');
      $btn.addClass('monthnav_current');

      ns.getCalendarInfo();
    };

    ns.clickCalendarDay = function(e) {
      e.preventDefault();

      var $btn   = $(e.currentTarget);

      $('.calnavday').find('a').removeClass('daynav_current');
      $btn.find('a').addClass('daynav_current');

      ns.getCalendarInfo();
    };

    ns.clickToEvent = function(e) {
      e.preventDefault();

      var $btn   = $(e.currentTarget),
          view   = $btn.data('view'),
          year   = $('.tabspace').data('year'),
          month  = $btn.data('month'),
          day    = $btn.data('day');

      $('.calnavday').find('a').removeClass('daynav_current');
      $('.calnavmon').removeClass('monthnav_current');
      $('#month-row').find('td:nth-child(' + month + ')').addClass('monthnav_current');
      $('.calnavview').removeClass('clocknav_current');
      $('#day-view').addClass('clocknav_current');

      ns.getCalendarInfo(view, year, month, day);
    };

    ns.getCalendarInfo = function(view=false, year=false, month=false, day=false) {
      var route    = $('.tabspace').data('route'),
          $filters = $('.event-filters');

      if (view === false && year === false && month === false && day === false) {
        var view     = $('.calnavview.clocknav_current').data('view'),
            year     = $('.tabspace').data('year'),
            month    = $('.calnavmon.monthnav_current').data('month'),
            day      = $('.calnavday').find('.daynav_current').parent().data('day');
      }

      $filters.removeClass('event-filters').addClass('event-filters-new');

      $.ajax({
          type: 'get',
          url:  route,
          data: {
              view  : view,
              year  : year,
              month : month,
              day   : day,
          },
          success: function(data) {
              $('.dayslineup').html(data.daysLineup);
              $('.eventstable').html(data.html);

              ns.initFilters();
          },
      });
    };

    ns.initFilters = function() {
      $('.event-filters-new #events-all').click(function() {
        $('.events .event1, .events .event2').removeClass('hide');
        $('.events .event1, .events .event2').removeClass('hidden');
        $(this).removeClass('eventinactive');
        $('.event-filters-new ').removeClass('eventinactive');
      });

      $('.event-filters-new a').click(function() {
        var txtClass = $(this).attr('class');
        $(this).parent().toggleClass('eventinactive');
        // console.log(txtClass);
        $('.events .'+txtClass).parent().toggleClass('hidden');
        $('#events-all').addClass('eventinactive')
      });
    };
    // End calendar

    ns.initCalendar = function() {
      var $dates = $('#sidebar-calendar').data('dates');

      for (key in $dates) {
        for (var i = 0; i < $dates[key].length; i++) {
          $dates[key][i] = new Date($dates[key][i]).getTime();
        }
      }

      $('.single').pickmeup({
          flat: true,
          mode: 'multiple',
          trigger_event: 'touchstart',
          // Before rendering dates, highlight if from database
          render: function(date) {
            for (key in $dates) {
              if ($.inArray(date.getTime(), $dates[key]) > -1){
                  return {
                      class_name : key + ' click-calendar'
                  }
              }
            }
          },
          change: function(date) {
            $('#sidebar-calendar').data('last-click-date', date[date.length-1]);
            // var route = $('#sidebar-calendar').data('route-for-click') + '#date-' + date[date.length-1];

            // window.location.href = route;
          },
      });
      // Little hack to deselect current day: PickMeUp forces you to have a default date :(
      // $('.pmu-today').click();
    };

    ns.clickSidebarCalendarDay = function() {
      setTimeout(function () {
        var route = $('#sidebar-calendar').data('route-for-click') + '#date-' + $('#sidebar-calendar').data('last-click-date');

        window.location.href = route;
      }, 500);
    };

    ns.initCalendarDay = function() {
        var hash   = window.location.hash.substring(1),
            prefix = 'date-';

        if(prefix == hash.substring(0, prefix.length)) {
            var date  = hash.substring(prefix.length).split('-'),
                day   = parseInt(date[0]),
                month = parseInt(date[1]),
                year  = parseInt(date[2]),
                view  = $('#day-view').data('view');

            if (view != null && day != null && month != null && year != null) {
                ns.getCalendarInfo(view, year, month, day);

                $('.calnavview').removeClass('clocknav_current');
                $('#day-view').addClass('clocknav_current');
                $('.calnavmon').removeClass('monthnav_current');
                $("#month-row").find("[data-month='" + month + "']").addClass('monthnav_current');
                $('.tabspace').data('year', year);
                $('.calnavyear').first().find('a').text(' << ' + (year - 1));
                $('.calnavyear').last().find('a').text((year + 1) + ' >> ');
            }
        }
    };

    ns.authorization = function(e) {
        e.preventDefault();

        if (!$('.radiobutton:checked').val()) {
            return false;
        }

        var $btn   = $(e.currentTarget);

        $("#log-in").addClass('log-in-active');
        $("#scrollUp").css({
            "z-index": '0'
        });
    };

    ns.setUserVote = function(e) {
        e.preventDefault();

        if (!$('.radiobutton:checked').val()) {
            return false;
        }

        var $btn        = $(e.currentTarget),
            questionId  = $('.radiobutton:checked').parent().data('question-id'),
            interviewId = $('.radiobutton:checked').parent().data('interview-id'),
            csrfToken   = $('#interview-info').data('csrf-token'),
            route       = $('#interview-info').data('route'),
            userId      = $('#interview-info').data('user-id'),
            userIp      = $('#interview-info').data('user-ip'),
            dateTime    = $('#interview-info').data('date-time');

        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': csrfToken
          }
        });
        $.ajax({
            type: 'post',
            url:  route,
            data: {
                interview_question_id: questionId,
                user_id:               userId,
                user_ip:               userIp,
                vote_date_time:        dateTime,
                interview_id:          interviewId,
            },
            async: false,
            success: function(data) {
                $('.poll-results-list').html(data.html);
            },
        });

        ns.getResultsInterview($btn);
    };

    ns.getResultsInterview = function($btn) { //$(this)
        $btn.parents('.poll-block').find('.poll-vote-list').fadeOut(500);
        $btn.parents('.poll-block').find('.poll-results-list').delay(500).fadeIn(500);
        $btn.hide();
        $btn.parents('.poll-block').find('.poll-results-list li .percentage').each(function() {
          var value = $(this).text();

          $(this).closest('li').find('.nul').removeClass('inner-bar null').addClass('voted');
          $(this).closest('li').find('.inner-bar').animate({width: value}, 1000);
          $(this).closest('li').find('.voted').delay('1000').animate({width: value}, 1000);
          $('.percentage').delay('2000').fadeIn('fast');
        });

        return false;
    };

    ns.clickReloadGraphic = function (e) {
        e.preventDefault();

        var $btn      = $(e.currentTarget),
            csrfToken = $btn.parent().data('csrf-token'),
            dataUrl   = $btn.data('url'),
            fullUrl   = $btn.parent().data('full-url'),
            $parent   = $btn.parent().parent();

        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': csrfToken
          }
        });
        $.ajax({
            type: 'post',
            url:  fullUrl,
            data: {
                url: dataUrl,
            },
            success: function(data) {
                $parent.find('.graphic-iframe').html(data.html);
                $btn.parent().find('a').removeClass('acurrent');
                $btn.addClass('acurrent');
            },
        });
    };

    ns.initGraphics = function() {
        $(".multichart").owlCarousel({
             stopOnHover: true,
             pagination: true,
             items: 1,
             itemsDesktop: [1199,
               1
             ],
             itemsDesktopSmall: [
               979, 1
             ],
             itemsTablet: [768, 1],
             itemsMobile: [479, 1],

           });
           $('.chartnum').click(function(){
           var curindex = ($(this).parent('li').index());
           $(this).parents('.multichart-wrapper').find('.multichart').trigger('owl.jumpTo', curindex);
        });
    };

    ns.clickChangeTab = function(e) {
      e.preventDefault();

      var $btn      = $(e.currentTarget);

      $btn.parent().parent().find('a').removeClass('active');
      $btn.addClass('active');
    };

    ns.submitAuthForm = function(e) {
        e.preventDefault();

        var $form  = $(e.currentTarget),
            $error = $form.find('.error'),
            type   = $form.attr('method'),
            url    = $form.attr('action');

        $error.addClass('hidden');

        $.ajax({
            type: type,
            url:  url,
            data: $form.serialize(),
            success: function(data) {
                if(data.result) {
                    window.location.reload();
                } else {
                    $error.removeClass('hidden');
                }
            }
        });
    };

    ns.submitRegForm = function(e) {
        e.preventDefault();

        var $form  = $(e.currentTarget),
            $error = $form.find('.error'),
            $btn   = $form.find('button'),
            type   = $form.attr('method'),
            url    = $form.attr('action');

        $error.addClass('hidden');
        $btn.attr('disabled', 'disabled');

        $.ajax({
            type: type,
            url:  url,
            data: new FormData($form.get(0)),
            processData: false,
            contentType: false,
            success: function(data) {
                $form.find('input').val(null);
                //$form.find('.radio').val(null);
                $btn.removeAttr('disabled');

                $("#create-account").removeClass('log-in-active');
                $("#check-email").addClass('log-in-active');
            },
            error: function(data) {
                for(var field in data.responseJSON) {
                    $form.find('#' + field).parent().find('.error').removeClass('hidden').text(data.responseJSON[field][0])
                }

                $btn.removeAttr('disabled');
            }
        });
    };

    ns.submitCommentForm = function(e) {
        e.preventDefault();

        var $form  = $(e.currentTarget),
            $btn   = $form.find('button'),
            type   = $form.attr('method'),
            url    = $form.attr('action');

        $form.find('.error').text('').addClass('hidden');
        $btn.attr('disabled', 'disabled');

        $.ajax({
            type: type,
            url:  url,
            data: new FormData($form.get(0)),
            processData: false,
            contentType: false,
            success: function(data) {
                $form.find('input').val(null);
                $form.find('textarea').val(null);
                //$form.find('.radio').val(null);
                $btn.removeAttr('disabled');

                $("#send-comment").addClass('log-in-active');
                $("#scrollUp").css({
                    "z-index": '0'
                });
            },
            error: function(data) {
                for(var field in data.responseJSON) {
                    $form.find('#' + field).parent().find('.error').text(data.responseJSON[field][0])
                }
                $form.find('.error').removeClass('hidden');

                $btn.removeAttr('disabled');
            }
        });
    };

    ns.checkChildCommentForm = function(e) {
        e.preventDefault();

        var $btn  = $(e.currentTarget);

        if ($btn.parent().parent().find('.child-comment-form-template').hasClass('hidden')) {
          $btn.parent().parent().find('.child-comment-form-template').removeClass('hidden');
        } else {
          $btn.parent().parent().find('.child-comment-form-template').addClass('hidden');
        }
    };

    ns.submitSubscribeForm = function(e) {
        e.preventDefault();

        var $form  = $(e.currentTarget),
            $btn   = $form.find('button'),
            type   = $form.attr('method'),
            url    = $form.attr('action');

        $form.find('.error').text('').addClass('hidden');
        $btn.attr('disabled', 'disabled');

        $.ajax({
            type: type,
            url:  url,
            data: new FormData($form.get(0)),
            processData: false,
            contentType: false,
            success: function(data) {
                $form.find('input').val(null);

                $form.find('.success').removeClass('hidden');

                $btn.removeAttr('disabled');;
            },
            error: function(data) {
                for(var field in data.responseJSON) {
                    $form.find('#' + field).parent().find('.error').text(data.responseJSON[field][0])
                }
                $form.find('.error').removeClass('hidden');

                $btn.removeAttr('disabled');
            }
        });
    };

    ns.init();
})(jQuery);

