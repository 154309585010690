$( document ).ready(function() {

  ///////////////SAME HEIGHT////////////////////

  function sameHeight (container) {
    var maxValue=0,
        $container = $(container),
        downHeight = $container.find(".pres-download").height();

     $container.each(function () {
        $container.css({'height':''});
        if ($(this).height() > maxValue){
          maxValue = $(this).height();
        }
     });
     $container.height(maxValue+50);
  }

  sameHeight (".same-height");
  // new height for new orientation
  $( window ).resize(function() {
    sameHeight (".same-height");
  });

  sameHeight (".opros");
  // new height for new orientation
  $( window ).resize(function() {
    sameHeight (".opros");
  });

  /////////////// GALLERY ////////////

  // document.getElementById('links').onclick = function (event) {
  $("#links").on('click', function(){
      event = event || window.event;
      var target = event.target || event.srcElement,
          link = target.src ? target.parentNode : target,
          options = {index: link, event: event},
          links = this.getElementsByTagName('a');
      blueimp.Gallery(links, options);   
  });

  ////////////// SUBMENU EMULATION /////////////////

  var menuItem = $(".nav").find(".dropdown").find("a");

  menuItem.each(function(index, el) {
    if ($(this).attr("href") == "energopack3.html") {
      $(this).parent().next().find("a").css({
        "padding-left": '33'
      });
    }  
  });

  ///////////////SAME HEIGHT END////////////////////


  // #28 - scroll to element
    $(".anchor").on('click', function(event) {
      event.stopPropagation();
      console.log(" click! ");
      var
          id    = $(this).attr('href'),
          top   = $(id).offset().top,
          top   = top - 130;

      $('body,html').animate({scrollTop: top}, 1000);
    }); 
  // #28 - end

  // #27
    // $(".toggle-search").on('click', function(event) {
    //   console.log(" search click ");
    //   event.preventDefault();
    //   $(".navbar-collapse").removeClass('in');
    // });
  // #27 - end

  // ========= SEARCH CONTAINER FIX===========

  // var searchCount = 0;

  // $(".toggle-search").on('click', function(event) {
  //   searchCount = searchCount + 1;
  //   event.preventDefault();
  //   if ((searchCount % 2)>0) {
  //     $(".search-container").slideDown(300);
  //   }
  //   else {
  //     $(".search-container").slideUp(300);
  //   }
  // });


  // #10
  // RELOAD ON CHANGE ORIENTATION

  // window.addEventListener("orientationchange", function() {
  //   window.location.reload();
  // }, false);

  // #5 SLICK SLIDER

  if ($('.slidebottom').length > 1) {
    $('.sliderbottom').slick();
    $(".slide__nav__left").on('click', function(event) {
      event.preventDefault();
      $('.sliderbottom').slick("prev");
    });
    $(".slide__nav__right").on('click', function(event) {
      event.preventDefault();
      $('.sliderbottom').slick("next");
    });
  }
  else {
    $(".slide__nav").hide();
  }

  // #22
  $("#search-bar").on('change', function(event) {
    event.preventDefault();
    event.stopPropagation();
  });

  // POPUPS
  $(".popup-login-close").on('click', function(event) {
    event.preventDefault();
    $("#log-in, #create-account").removeClass('log-in-active');
    // $(".popup-wrapp").hide();
  });
  $(".log-in-btn").on('click', function(event) {
    event.preventDefault();
    $("#log-in").addClass('log-in-active');
    $("#scrollUp").css({
      "z-index": '0'
    });
    // $(".popup-wrapp").show();
  });
  $(".log-in-reg").on('click', function(event) {
    event.preventDefault();
    $("#log-in").removeClass('log-in-active');
    $("#create-account").addClass('log-in-active');
  });

});